
  @import '/src/shared/styles/_variables.scss';

.animation>* {
  --animation-duration:2s;
  animation:nc-loop-squares-anim-2 var(--animation-duration) infinite
}
.animation>:nth-child(1){
  opacity:0;animation-delay:calc(-1 * var(--animation-duration)/ 2)
}
.animation>:nth-child(2){
  opacity:.5;animation-delay:calc(-1 * var(--animation-duration)/ 4)
}
.animation>:nth-child(4){
  animation-delay:calc(var(--animation-duration)/4)
}

@keyframes nc-loop-squares-anim-2{
  0%,100% {
      opacity:1
  }
  50% {
      opacity:0
  }
}
