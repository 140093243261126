
  @import '/src/shared/styles/_variables.scss';

.page {
  width: 100%;
  padding: 64px 0 0 0;
  
  @include desktop {
    padding: 0 0 0 220px;
  }

  .page-body {
    z-index: 1;
  }

  &-loading {
    .loading {
      width: 100%;
    }
  }

  .loading {
    @include desktop {
      width: calc(100% - 220px);
    }
  }
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;


  .loader {
    width: 64px;
    height: 64px;
  }
}

