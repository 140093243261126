/*
          GIORGIO SANS
  **/
@font-face {
  font-family: "GiorgioSans-Heavy";
  src: url("../fonts/GiorgioSans/GiorgioSans-Heavy-Web.woff") format("woff");
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
}

/*
          INTER
  **/
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
}

/*
          SPACE MONO
  **/

  @font-face {
    font-family: "Space mono";
    src: url("../fonts/SpaceMono/SpaceMono-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
  }


  @font-face {
    font-family: "Space mono";
    src: url("../fonts/SpaceMono/SpaceMono-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
  }
