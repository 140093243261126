* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: pre-line;
}

h1 {
  /* Headings/40px */
  font-family: $giorgio;
  font-weight: 900;
  font-size: 2.8rem; // font-size: 48px;
  line-height: 2.8rem;
  text-transform: uppercase;
  color: #111111;
}

h2 {
  font-family: $inter;
  font-weight: bold;
  font-size: 1.1251rem;
  line-height: 1.375rem;
}

h3 {
  font-family: $inter;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

h4 {
  font-family: $inter;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #111111;
}

main {
  z-index: 1;
  position: relative;
}

p,
li {
  font-family: $inter;
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;

  b {
    font-weight: 700;
  }

  &.error {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #cb2929;
    margin: 8px 0px;
  }
}

label, fieldset {
  font-family: $inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #111111;
  margin: 7px 0px;
  flex-direction: column;

  .checked {
    position: absolute;
    background: black;
    left: 4px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    
    @include desktop {
      width: 10px;
      height: 10px;
      left: 3px;
    }
  }
}

input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 20px 16px;
  position: static;
  height: 48px;
  left: 0%;
  right: 0%;
  top: calc(50% - 24px + 12px);
  background: #ffffff;
  border: 1px solid $grey-dark;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 8px 0px;
  min-width: 280px;
  width: 100%;

  &::placeholder {
    color: #828282;
    font-weight: 500;
  }

  &.error {
    border: 2px solid #D81C32;
    color: #cb2929;
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
      color: unset;
    }
  }
}

a {
  font-family: $inter;
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  text-decoration-line: underline;
  cursor: pointer;
}

button {
  &.primary {
    padding: 10px 20px;
    font-family: $giorgio;
    font-weight: 900;
    color: #ffffff;
    background: #111111;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    width: 100%;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: 0.01em;
    
    @include desktop {
      width: fit-content;
    }

    &.yellow-text {
      color: #f0f01f;
    }

    &.small-text {
      font-size: 24px;
    }
  }

  &.secondary {
    padding: 10px 20px;
    font-family: $giorgio;
    font-weight: 900;
    color: #111111;
    background: #f0f01f;
    border-radius: 4px;
    width: 100%;

    font-size: 32px;
    line-height: 100%;
    letter-spacing: 0.01em;
    
    @include desktop {
      width: fit-content;
    }

    &.small-text {
      font-size: 24px;
    }
  }

  &.naked {
    background: none;
    color: #000000;
    font-family: $inter;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &.inside-input {
    padding: 8px 16px;
    color: #ffffff;
    font-family: $inter;
    background-color: $grey-very-very-dark;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  &.full-width {
    width: 100%;
    min-width: 100%;
  }

  &.horizontal-group {
    margin: 0 16px;
  }
}

.wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

form {
  margin-top: 30px;
  max-width: 450px;
}

ul li {
  &.incorrect {
    label {
      color: #D81C32;
    }

    input[type=radio] + .radio-label:before, 
    input[type=checkbox] + .radio-label:before {
      border: 1px solid #D81C32;
      background-color: #D81C32;
    }
  }

  &.correct {
    label {
      color: #1ABA46;
    }

    input[type=radio] + .radio-label:before, 
    input[type=checkbox] + .radio-label:before {
      border: 1px solid #1ABA46;
      background-color: #1ABA46;
    }
  }
}

input[type="radio"], input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  height: 0;
  margin: 0;
  padding: 0;

  + .radio-label {
    &:before {
      content: '';
      background: #F4F4F4;
      border-radius: 100%;
      border: 1px solid $grey-dark;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      position: relative;
      margin-right: 10px;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }
  }
  &:checked {
    + .radio-label {
      &:before {
        background-color:#111111;
        box-shadow: inset 0 0 0 3px #F4F4F4;
      }
    }
  }
  &:focus {
    + .radio-label {
      &:before {
        border: 1px solid $grey-dark;
      }
    }
  }
  &:disabled {
    + .radio-label {
      &:before {
        box-shadow: inset 0 0 0 4px #fff;
        border: 1px solid $grey-dark;
        background: #F4F4F4;
      }
    }
  }
  + .radio-label {
    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }
}

// Success Notification
.Toastify__toast--success {
  border-radius: 8px !important;
  border: 2px solid #1ABA46 !important;
  background-color: #E9F9ED !important;

  .Toastify__toast-body {
    background-color: #E9F9ED !important;
    color: #1C8B3B;
  }

  .Toastify__close-button {
    color:#1ABA46;
    margin-top: auto;
    margin-bottom: auto;
  }
}

// Failure Notification
.Toastify__toast--error {
  border-radius: 8px !important;
  border: 2px solid #D81C32 !important;
  background-color: #FCE9EB !important;

  .Toastify__toast-body {
    background-color: #FCE9EB !important;
    color: #D81C32 !important;
  }

  .Toastify__close-button {
    color:#D81C32;
    margin-top: auto;
    margin-bottom: auto;
  }
}


// Info Notification
.Toastify__toast--info {
  border-radius: 8px !important;
  border: 2px solid #55DAFC !important;
  background-color: #DDF8FE !important;

  .Toastify__toast-body {
    background-color: #DDF8FE !important;
    color: #215765 !important;
  }

  .Toastify__close-button {
    color:#55DAFC;
    margin-top: auto;
    margin-bottom: auto;
  }
}

#payment-form {
  margin-top: 40px;
  button {
    width: 100%;
  }
}

.bm-menu-wrap {
  top: 64px;
}