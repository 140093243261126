.slick-slider {
  height: 100%;
  display: flex;
  padding: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  
  @include tablet {
    padding: 64px;  
  }
  
  @include desktop {
    justify-content: center;
    align-items: center;
  }

  .next {
    position: absolute;
    display: none;
    right: 0;
    
    @include desktop {
      display: block;
    }
  }
  
  .previous {
    position: absolute; 
    display: none;
    left: 0;
    
    @include desktop {
      display: block;
    }
  }
}