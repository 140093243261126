.swiper {
  mask-image: linear-gradient(90deg, transparent 50px, rgba(0, 0, 0, 1) 65px, rgba(0, 0, 0, 1) calc(100% - 65px), transparent calc(100% - 50px));
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100% 100%;
  z-index: 0;
  padding: 0px 80px 0px 40px !important;
  left: -60px !important;
  width: 100%;

  &-wrapper {
    padding: 0 20px;
  }

  .swiper-button-prev, .swiper-button-next {
    height: 40px;
    background:rgb(17 17 17 / 70%);
    position: absolute;
    width: 40px;
    top: calc(50% - 20px);
    z-index: 1;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px 18px;
  }


  .swiper-button {
    &-disabled {
      display: none;
    }

    &-prev {
      background-image: url("/icons/chevron-left.svg");
      left: 60px;
    }

    &-next {
      background-image: url("/icons/chevron-right.svg");
      right: 60px;
    }
  }

  .swiper-slide {
    padding-right: 12px;
  }
}